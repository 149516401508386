import Sidebar from './sidebar'; // Import the Sidebar component
import Leaderboard from './Leaderboard'; // Import the Leaderboard component
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext'; // Import AuthContext

function ButtonGamePage() {
  const { loggedInUser, buttonScore, updateButtonScore, loading } = useContext(AuthContext);
  const [gridSize] = useState(20); // 20x20 grid
  const [snake, setSnake] = useState([[10, 10]]); // Snake starting position
  const [direction, setDirection] = useState([0, 1]); // Initial direction: right
  const [food, setFood] = useState(generateFoodPosition());
  const [gameOver, setGameOver] = useState(false);
  const [currentGameScore, setCurrentGameScore] = useState(0); // Track the current game score

  // Generate a random food position
  function generateFoodPosition() {
    let foodPosition;
    do {
      foodPosition = [
        Math.floor(Math.random() * gridSize),
        Math.floor(Math.random() * gridSize),
      ];
    } while (snake.some(segment => segment[0] === foodPosition[0] && segment[1] === foodPosition[1]));
    return foodPosition;
  }

  // Handle game tick
  useEffect(() => {
    if (gameOver) return;

    const interval = setInterval(() => {
      setSnake((prevSnake) => {
        const newHead = [
          (prevSnake[0][0] + direction[0] + gridSize) % gridSize,
          (prevSnake[0][1] + direction[1] + gridSize) % gridSize,
        ];

        if (prevSnake.some(segment => segment[0] === newHead[0] && segment[1] === newHead[1])) {
          setGameOver(true);
          return prevSnake;
        }

        let newSnake = [newHead, ...prevSnake];

        if (newHead[0] === food[0] && newHead[1] === food[1]) {
          // If the snake eats the food, increase the current game score
          setCurrentGameScore(newSnake.length); // Increment the current game score
          
          // If the new score beats the current saved score, update the database
          if (newSnake.length > buttonScore) {
            updateButtonScore(newSnake.length); // Update score if it beats the current one
          }
          
          setFood(generateFoodPosition());
        } else {
          newSnake.pop();
        }

        return newSnake;
      });
    }, 200);

    return () => clearInterval(interval);
  }, [direction, food, gameOver, gridSize, buttonScore, updateButtonScore]);

  // Handle keyboard input
  useEffect(() => {
    function handleKeyDown(event) {
      const keyDirectionMap = {
        w: [-1, 0],
        s: [1, 0],
        a: [0, -1],
        d: [0, 1],
      };
      const key = event.key.toLowerCase();
      if (keyDirectionMap[key]) {
        setDirection((prevDirection) => {
          const [dx, dy] = keyDirectionMap[key];
          if (dx !== -prevDirection[0] && dy !== -prevDirection[1]) {
            return keyDirectionMap[key];
          }
          return prevDirection;
        });
        if (gameOver) {
          setGameOver(false);
          setSnake([[10, 10]]); // Reset snake position
          setDirection([0, 1]); // Reset direction
          setFood(generateFoodPosition()); // Reset food position
          setCurrentGameScore(0); // Reset the current game score when restarting the game
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [gameOver]);

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <div
        className="button-game-page"
        style={{
          flex: 1,
          padding: '20px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {loggedInUser ? (
          <div>
            <h1>Hello, {loggedInUser.username}!</h1>
            <p>Your current saved score is: {buttonScore}</p>
            <p>Your current game score is: {currentGameScore}</p> {/* Display the current game score */}
            {gameOver && <p style={{ color: 'red' }}>Game Over! Press any WASD key to restart.</p>}
            <div
              style={{
                display: 'grid',
                gridTemplateRows: `repeat(${gridSize}, 20px)`,
                gridTemplateColumns: `repeat(${gridSize}, 20px)`,
                gap: '1px',
                backgroundColor: 'black',
              }}
            >
              {[...Array(gridSize)].map((_, row) =>
                [...Array(gridSize)].map((_, col) => {
                  const isSnake = snake.some(segment => segment[0] === row && segment[1] === col);
                  const isFood = food[0] === row && food[1] === col;
                  return (
                    <div
                      key={`${row}-${col}`}
                      style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: isSnake ? 'green' : isFood ? 'red' : 'white',
                      }}
                    />
                  );
                })
              )}
            </div>
          </div>
        ) : (
          <p>Please log in to play the game and increase your score.</p>
        )}
        <Leaderboard />
      </div>
    </div>
  );
}

export default ButtonGamePage;
