import React, { useState, useEffect } from 'react';
import { firestore } from './firebase-config'; // Import Firestore
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore'; // Firestore functions

function Leaderboard() {
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set up a real-time listener
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, orderBy('buttonScore', 'desc'), limit(50));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const leaderboardData = querySnapshot.docs.map((doc) => ({
        username: doc.id, // Username is the document ID
        buttonScore: doc.data().buttonScore || 0, // Default to 0 if no score exists
      }));

      setLeaderboard(leaderboardData);
      setLoading(false);
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading leaderboard...</div>;
  }

  return (
    <div className="leaderboard">
      <h1>Leaderboard</h1>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Username</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {leaderboard.map((user, index) => (
            <tr key={user.username}>
              <td>{index + 1}</td> {/* Rank starts from 1 */}
              <td>{user.username}</td>
              <td>{user.buttonScore}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Leaderboard;
