import { BrowserRouter as Router, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { firestore } from './firebase-config';
import { collection, getDocs, query, where, onSnapshot } from 'firebase/firestore';

function Sidebar() {
  const [users, setUsers] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);

  // Fetch all users
  const fetchUsers = async () => {
    try {
      const usersRef = collection(firestore, 'users');
      const querySnapshot = await getDocs(usersRef);
      const usersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Subscribe to online users
  const subscribeToOnlineUsers = () => {
    const onlineUsersRef = query(
      collection(firestore, 'users'),
      where('online', '==', true) // Assuming 'online' is a boolean field
    );

    return onSnapshot(onlineUsersRef, (snapshot) => {
      const onlineData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOnlineUsers(onlineData);
    });
  };

  // Merge and sort users
  const getSortedUsers = () => {
    // Mark online users and merge into a single list
    const mergedUsers = users.map(user => ({
      ...user,
      isOnline: onlineUsers.some(onlineUser => onlineUser.id === user.id),
    }));

    // Sort by `isOnline` (true first)
    return mergedUsers.sort((a, b) => b.isOnline - a.isOnline);
  };

  useEffect(() => {
    fetchUsers();
    const unsubscribe = subscribeToOnlineUsers();
    return () => unsubscribe();
  }, []);

  const sortedUsers = getSortedUsers();

  return (
    <div style={{ width: '300px', padding: '20px', borderRight: '1px solid #ccc' }}>
      <h2>Users</h2>
      <div
        style={{
          maxHeight: '300px', // Set max height for scrolling
          overflowY: 'auto', // Enable vertical scrolling
          paddingRight: '10px', // Space for scrollbar
        }}
      >
        {sortedUsers.map(user => (
          <NavLink to={`/profile/${user.username}`} activeClassName="active">
          <div
            key={user.id}
            style={{
              marginBottom: '10px',
            //   borderBottom: '1px solid #ddd',
              paddingBottom: '5px',
            }}
          >
            ({user.rank}) {user.username}
            {/* {user.isOnline && (
              <span style={{ color: '#2be249', marginLeft: '5px' }}>● Online</span>
            )}
            {!user.isOnline && (
              <span style={{ color: '#414241', marginLeft: '5px' }}>● Offline</span>
            )} */}
          </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
