import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs, updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "./firebase-config";
import { AuthContext } from "./AuthContext";
import Post from "./Post";

function UserProfile() {
  const { username } = useParams(); // Retrieve the username from the URL
  const { loggedInUser } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch user details from Firestore
        const userDocRef = doc(firestore, "users", username);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserDetails(userDoc.data());
        } else {
          console.error("User not found");
        }

        // Fetch user's posts
        const postsQuery = query(
          collection(firestore, "threads"),
          where("username", "==", username)
        );
        const postsSnapshot = await getDocs(postsQuery);
        const posts = postsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUserPosts(posts);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [username]);

  const handleLikeDislike = async (postId, action) => {
  if (!loggedInUser) {
    setMessage("You must be logged in to like or dislike a post.");
    return;
  }

  try {
    const postRef = doc(firestore, "threads", postId);
    const postDoc = await getDoc(postRef);

    if (postDoc.exists()) {
      const data = postDoc.data();
      const { likedBy, dislikedBy } = data;

      if (action === "like") {
        if (likedBy.includes(loggedInUser.username)) {
          await updateDoc(postRef, {
            likes: data.likes - 1,
            likedBy: likedBy.filter((user) => user !== loggedInUser.username),
          });
        } else {
          if (dislikedBy.includes(loggedInUser.username)) {
            await updateDoc(postRef, {
              dislikes: data.dislikes - 1,
              dislikedBy: dislikedBy.filter((user) => user !== loggedInUser.username),
            });
          }
          await updateDoc(postRef, {
            likes: data.likes + 1,
            likedBy: [...likedBy, loggedInUser.username],
          });
        }
      } else if (action === "dislike") {
        if (dislikedBy.includes(loggedInUser.username)) {
          await updateDoc(postRef, {
            dislikes: data.dislikes - 1,
            dislikedBy: dislikedBy.filter((user) => user !== loggedInUser.username),
          });
        } else {
          if (likedBy.includes(loggedInUser.username)) {
            await updateDoc(postRef, {
              likes: data.likes - 1,
              likedBy: likedBy.filter((user) => user !== loggedInUser.username),
            });
          }
          await updateDoc(postRef, {
            dislikes: data.dislikes + 1,
            dislikedBy: [...dislikedBy, loggedInUser.username],
          });
        }
      }

      // Update UI for the specific post
      setUserPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? {
                ...post,
                likes:
                  action === "like"
                    ? likedBy.includes(loggedInUser.username)
                      ? post.likes - 1
                      : post.likes + 1
                    : post.likes,
                dislikes:
                  action === "dislike"
                    ? dislikedBy.includes(loggedInUser.username)
                      ? post.dislikes - 1
                      : post.dislikes + 1
                    : post.dislikes,
              }
            : post
        )
      );
    }
  } catch (error) {
    console.error("Error updating likes/dislikes:", error);
    setMessage("An error occurred while updating the post.");
  }
};

  const toggleExpandPost = (postId) => {
    setUserPosts((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId ? { ...post, isExpanded: !post.isExpanded } : post
      )
    );
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="user-profile" style={{ padding: "20px" }}>
      <h1>{username}'s Profile</h1>
      {userDetails ? (
        <>
          <h2>Stats</h2>
          {/* <p><strong>Email:</strong> {userDetails.email}</p> */}
          <p><strong>Rank:</strong> {userDetails.rank}</p>
          <p><strong>Score:</strong> {userDetails.buttonScore}</p>

          <h2>Posts</h2>
          <div style={{ maxHeight: "400px", overflowY: "auto", paddingRight: "10px" }}>

          
          {userPosts.length > 0 ? (
            userPosts.map((post) => (
              <Post
                key={post.id}
                post={post}
                loggedInUser={loggedInUser}
                onLikeDislike={handleLikeDislike}
                onExpandToggle={toggleExpandPost}
                onDelete={() => {}} // Not needed for user profile
                isAdmin={false} // No delete button for user profile
              />
            ))
          ) : (
            <p>No posts by this user.</p>
          )}
          </div>
        </>
        
      ) : (
        <p>User not found.</p>
      )}
    </div>
  );
}

export default UserProfile;
